.container {
    background: #fff;
    margin-top: 10px;

}

body {
    /* overflow-y: hidden;  */
    overflow-x: hidden;
}

/* .banner {

    box-shadow: 1px -9px 8px 7px #ccc; 
    border-radius: 20px;
} */

.banner img {
    width: 95%;

    margin: 10px 2%;
    box-shadow: 0 0 30px #ccc;
    border-radius: 20px;
    border: 20px solid #fff;
}



.form h1 {
    font-weight: 100px;
    font-size: 22px;
    padding: 30px 0;
    text-shadow: 1px 1px 2px lightgray;
    margin-top: 0px;
}

label.col-md-4 h6 {
    font-weight: 400;
}

.col-md-8 input, .col-md-8 select {
    font-weight: 300;
    width: 100% !important;
}

.successreg {
    padding: 10px 50px;
    display: table;
    margin-left: 35%;
}

.col-md-8.captcha .cap_wrapper {
    margin: auto;
    display: table;
    width: 100%;
    text-align: start;
    background: lightgray;
    padding: 15px;
    border-radius: 8px;
}

.mobile_image {
    display: none;
}

@media screen and (min-width: 320px) and (max-width: 757px)  {
    .mobile_image {
        display:block}
    }

    /* @media screen and (min-width: 320px) and (max-width: 757px) and (orientation: landscape) {
  html {
    display:none;
  }
} 
 @media only screen and (max-width: 600px) {
    .container.page-container {
    padding-top: 50px;
}    */


    /* .container{max-width: 100%!important;}
.col-md-6.captcha .cap_wrapper {
    width: 223px;
  } */
  