.invalid {
  border: 1px solid red !important;
}



input,
textarea {
  outline: none !important;
  box-shadow: none !important;
}

.divider:after,
.divider:before {
  content: "";
  flex: 1;
  height: 2px;
  background: rgb(170, 170, 170);
}

/*==== Login page css ====*/
.h-custom {
  height: calc(100% - 73px);
}

@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}

.invalid {
  border: 1px solid red !important;
}

input,
textarea {
  outline: none !important;
  box-shadow: none !important;
}

.table-hover>tbody>tr:hover>* {
  color: inherit !important;
  cursor: pointer;
}

.camp_table td {
  vertical-align: middle;
  text-transform: capitalize;
}

.registered, .registered:hover {
  background: #1A89CE !important;
  color: #fff !important;
}

.verified, .verified:hover {
  background: #3F9E2E !important;
  color: #fff !important;
}

.sample_collected, .sample_collected:hover {
  background: #F7EC2F !important;
}

.sample_denied, .sample_denied:hover {
  background: #ED1B51 !important;
  color: #fff !important;
}

.report_done, .report_done:hover {
  background: #06623D !important;
  color: #fff !important;
}

.report_failed, .report_failed:hover {
  background: #890404 !important;
  color: #fff !important;
}

.doctor_pre_pres_done, .doctor_pre_pres_done:hover {
  background: #C3C3C6 !important;
}

.doctor_post_pres_done, .doctor_post_pres_done:hover {
  background: #222222 !important;
  color: #fff !important;
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 0 !important;
  }
}

.table-hover>tbody>tr:hover>* {
  color: inherit !important;
  cursor: pointer;
}

.camp_table td {
  vertical-align: middle;
  text-transform: capitalize;
}

.registered, .registered:hover {
  background: #6ba9e7 !important;
  color: #fff !important;
}

.verified, .verified:hover {
  background: #3f9e2e !important;
  color: #fff !important;
}

.sample_collected, .sample_collected:hover {
  background: #F7EC2F !important;
}

.sample_denied, .sample_denied:hover {
  background: #ED1B51 !important;
  color: #fff !important;
}

.report_done, .report_done:hover {
  background: #06623D !important;
  color: #fff !important;
}

.report_failed, .report_failed:hover {
  background: #890404 !important;
  color: #fff !important;
}

.doctor_pre_pres_done, .doctor_pre_pres_done:hover {
  background: #C3C3C6 !important;
}

.doctor_post_pres_done, .doctor_post_pres_done:hover {
  background: #222222 !important;
  color: #fff !important;
}

.color-user-box {
  background: #3f9e2e;
  border: 1px solid black;
}

.color-user-box2 {
  background: #6ba9e7;
  border: 1px solid black;
}

.color-doc-box {
  background: #C3C3C6;
  border: 1px solid black;
}

.color-doc-box2 {
  background: #fff;
  border: 1px solid black;
}