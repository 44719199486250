.h-custom {
  height: calc(100% - 73px);
}
@media (max-width: 450px) {
  .h-custom {
    height: 100%;
  }
}
.invalid {
  border: 1px solid red !important;
}
input,
textarea {
  outline: none !important;
  box-shadow: none !important;
}

